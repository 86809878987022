import { getAccessToken } from "AccessToken";
import { hotelxSocketUrls, hostUrl } from "client";
import React, { createContext, useState, useEffect } from 'react';
import io from 'socket.io-client';

export const SocketContext = createContext(null); // Use null as the initial value
export const SocketContextProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const token = getAccessToken();
  const urlInfo =  window.location.pathname

  useEffect(() => {
    const newSocket = hotelxSocketUrls.map(url =>
      
      io(url, { transports: ["polling"], upgrade: false }),      
    );

    const socketPort = newSocket?.filter(x => x?._opts?.hostname == hostUrl)[0]

    console.log(newSocket[0]?._opts?.hostname,hostUrl, hotelxSocketUrls,socketPort,"port");
    

    setSocket(socketPort);

    // Show the popup dialog when the user tries to leave the page
    const onBeforeUnload = () => {
      socketPort.emit('data', {
        Status: 'LOGOUT',
        HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
        UserID: JSON.parse(localStorage.getItem('LoggedInUser'))?.ID,
      });
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    // Reconnect when the token is available
    if (token) {
      socketPort.emit('data', {
        Status: 'LOGIN',
        HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
        UserID: JSON.parse(localStorage.getItem('LoggedInUser'))?.ID,
      });
    }

    // Cleanup function
    return () => {
      socketPort.disconnect();
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [token]); // Add token as a dependency

  const LOGOUT = () => {
    if (socket) {
      socket.emit('data', {
        Status: 'LOGOUT',
        HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
        UserID: JSON.parse(localStorage.getItem('LoggedInUser'))?.ID,
      });

      socket.disconnect();
    }
  }

  return (
    <SocketContext.Provider
      value={{
        socket,
        LOGOUT
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
